import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { PaginationType } from 'client/widget-components';
import { SliderLayout, sliderLayouts } from '../sliderCommonTypes';
import { featureFlags } from '@shared-services/feature-flags';

interface SlideShowLayoutPaginationStyles {
    container?: ResponsiveStylesStructured;
}
export type SlideShowLayoutPaginationStylesPerLayout = Record<
    SliderLayout,
    SlideShowLayoutPaginationStyles
>;
export type SliderLayoutsPaginationOverrides = Partial<
    Record<PaginationType, Partial<SlideShowLayoutPaginationStylesPerLayout>>
>;
const flag = featureFlags.getBoolean(
    `runtime.ssr.ssrSlider.multiplePaginationPerLayout.enabled`,
    false
);
const slideShowLayoutPaginationStylesInner = (
    numOfSlideSlots: number
): SliderLayoutsPaginationOverrides => ({
    [PaginationType.BULLETS]: {
        [sliderLayouts.LAYOUT_2]: {
            container: {
                [RuntimeMediaQuery.MOBILE]:
                    numOfSlideSlots < 3
                        ? {}
                        : {
                              position: 'relative',
                          },
            },
        },
    },
    [PaginationType.ARROWS]: {
        [sliderLayouts.LAYOUT_2]: {
            container: {
                [RuntimeMediaQuery.MOBILE]: {
                    width: `${100 * numOfSlideSlots}%`,
                    height: '100%',
                    position: 'relative',
                    left: 'unset', //reset left property
                },
            },
        },
        [sliderLayouts.LAYOUT_5]: {
            container: {
                [RuntimeMediaQuery.MOBILE]: {
                    width: `${100 * numOfSlideSlots}%`,
                    height: '100%',
                    position: 'relative',
                    left: 'unset', //reset left property
                },
            },
        },
    },
});
export const getSlideShowLayoutPaginationStylesOverrides = ({
    paginationType,
    slotsInFrame,
    layout,
}: {
    paginationType: PaginationType | undefined;
    slotsInFrame: number;
    layout: SliderLayout;
}): SlideShowLayoutPaginationStyles | undefined => {
    return paginationType
        ? slideShowLayoutPaginationStyles(slotsInFrame)?.[paginationType]?.[
              layout
          ]
        : {};
};

const slideShowLayoutPaginationStyles = flag
    ? slideShowLayoutPaginationStylesInner
    : () => {};
