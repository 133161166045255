import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DropdownProps } from './types';
import useEditor from 'client/widget-components/customHooks/useEditor';
import {
    Div,
    getResponsiveComponent,
} from 'client/widget-components/basicComponents';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import cssVars from 'client/widget-components/cssVariables';
import styled from 'styled-components';

const Dropdown = getResponsiveComponent('select', true);
const Option = styled.option`
    &:not(:disabled) {
        color: initial;
    }
    background-color: ${cssVars.bgWhite};
`;

interface Props extends DropdownProps {
    styles?: ResponsiveStylesProp;
}
const NativeDropdown: React.FC<Props> = ({
    selectedValue: defaultValue,
    placeholder,
    options = [],
    onChange,
    styles,
}) => {
    const selectRef = useRef<HTMLDivElement>(null);
    const { insideEditor } = useEditor();

    const [selectedValue, setSelectedValue] = useState(
        defaultValue?.value || ''
    );

    useEffect(() => {
        const selectEl = selectRef.current;
        if (selectEl) {
            const handleClick = (event: Event) => {
                if (insideEditor()) {
                    event.preventDefault();
                }
            };
            selectEl.addEventListener('mousedown', handleClick);
            return () => {
                selectEl.removeEventListener('mousedown', handleClick);
            };
        }
    }, []);

    const onSelect = useCallback(
        ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
            const selected = options.find(
                ({ value }) => value === target.value
            );
            if (selected) {
                setSelectedValue(target.value);
                onChange?.(selected);
            }
        },
        []
    );

    const dropdownStyles = [
        selectDefaultStyles,
        styles,
        selectArrowDefaultStyles,
        !selectedValue && {
            [RuntimeMediaQuery.COMMON]: {
                color: cssVars.secondaryTextColor,
            },
        },
    ];

    return (
        <Div
            styles={dropdownWrapperDefaultStyles}
            className='dropdown-component'
        >
            <Dropdown
                ref={selectRef as any}
                required
                value={selectedValue}
                onChange={onSelect}
                data-auto='dropdown-component'
                data-grab='dropdown-component'
                className='dropdown'
                styles={dropdownStyles}
            >
                <Option value='' disabled hidden>
                    {placeholder}
                </Option>
                {options.map(({ value, label, disabled }) => (
                    <Option
                        value={value}
                        key={value}
                        disabled={disabled}
                        data-auto='select-option'
                    >
                        {label}
                    </Option>
                ))}
            </Dropdown>
        </Div>
    );
};

const selectDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        height: '100%',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingInlineStart: '8px',
        border: `1px solid ${cssVars.borderColor}`,
        borderRadius: '3px',
        outline: 'none',
        boxShadow: 'none',
        lineHeight: 'normal',
        appearance: 'none',
        backgroundColor: 'inherit',
        cursor: 'pointer',
    },
};

const dropdownWrapperDefaultStyles: ResponsiveStylesProp = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
        height: '30px',
        maxHeight: '40px',
        border: '0px solid',
    },
};

const selectArrowDefaultStyles: ResponsiveStylesProp = {
    [RuntimeMediaQuery.COMMON]: {
        backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5769 5.5L12.5 6.4375L8.5 10.5L4.5 6.4375L5.42308 5.5L8.5 8.625L11.5769 5.5Z' fill='%23828894'/%3E%3C/svg%3E")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'calc(100% - 4px) center',
        ':dir(rtl)': {
            backgroundPosition: '4px',
        },
    },
};

export default NativeDropdown;
